<template>
  <div class="card">
    <div class="card-header">{{$t('home.invite-card.title')}}</div><!-- Convide sua Equipe -->
    <div class="card-body card-team">
      <form @submit.prevent="send" class="form-horizontal">
        <div class="row form-group">
          <div class="col-6">
            <input
              v-model="form.email"
              type="email"
              class="form-control"
              placeholder="Email"
              required
            />
          </div>
          <div class="col-6">
            <select-input
              v-model="form.role"
              :reduce="(roles) => roles.value"
              :options="roles"
              :clearable="false"
              class="yu-custom-select"
            ></select-input>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-12">
            <button
              type="submit"
              class="btn btn-secondary"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
            >
              {{$t('home.invite-card.btn-invite')}}
            </button><!-- Convidar -->
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <router-link to="/users" class="btn btn-secondary btn-outline"
        >{{$t('home.invite-card.btn-manage')}}</router-link
      ><!-- Gerenciar Usuários -->
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user.service';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'InviteCard',
  components: { SelectInput },
  data() {
    return {
      form: {
        email: '',
        role: 'user',
      },
      isSending: false,
    };
  },
  computed: {
    roles() {
      return [
        {
          label: this.$t('home.invite-card.select.adm'),
          value: 'admin',
        },
        {
          label: this.$t('home.invite-card.select.dev'),
          value: 'developer',
        },
        {
          label: this.$t('home.invite-card.select.fin'),
          value: 'billing',
        },
        {
          label: this.$t('home.invite-card.select.carrier'),
          value: 'user',
        },
        this.$store.state.account.whitelabel_id == null
          ? { label: this.$t('home.invite-card.select.info-security'), value: 'security' }
          : '',
        this.$store.state.account.whitelabel_id == null
          ? { label: this.$t('home.invite-card.select.sale-executive'), value: 'sales' }
          : '',
      ];
    },
  },
  methods: {
    send() {
      this.isSending = true;
      UserService.invite(this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Convite enviado',
            type: 'success',
          });
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .yu-custom-select .vs__dropdown-toggle {
  width: auto !important;
}
</style>
