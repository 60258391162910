<template>
  <div class="content">
    <PageHeader title="Dashboard" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-6">
          <div class="card">
            <div class="card-header">{{$t('home.acc.title')}}</div><!-- Informações da conta -->
            <div class="card-body block-el">
              <form action="#" class="form-horizontal">
                <div class="form-body">
                  <div class="form-group row">
                    <label class="control-label text-left col-md-3 weight-500">{{$t('home.acc.label')}}</label><!-- CONTA -->
                    <label class="control-label text-left col-md-9">{{ account.name }}</label>
                  </div>
                  <div class="form-group row">
                    <label
                      class="control-label text-left col-md-3 text-left weight-500"
                    >{{$t('home.acc.lbl-id')}}</label><!-- ID DA CONTA -->
                    <label class="control-label text-left col-md-9">{{ account.id }}</label>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-left col-md-3 weight-500">AUTH TOKEN</label>
                    <!-- <label
                      class="control-label text-left col-md-9"
                    >{{ account.auth_token }}</label> -->
                    <label class="control-label text-left col-md-9">
                      <HiddenInput :value="account.auth_token" :readonly="true" title="Token de autenticação" />
                    </label>
                  </div>
                  <!--<div class="form-group row">
            <label class="control-label text-right col-md-3">
              <template v-if="account.role == 'owner'">Dono</template>
              <template v-else-if="account.role == 'admin'">Administrador</template>
              <template v-else-if="account.role == 'developer'">Desenvolvedor</template>
              <template v-else-if="account.role == 'billing'">Financeiro</template>
              <template v-else-if="account.role == 'user'">Usuário</template>
            </label>
            <label class="control-label text-left col-md-9">ss</label>
                  </div>-->
                </div>
              </form>
            </div>
          </div>
          <div class="card">
            <div class="card-header">SMS</div>
            <div class="card-body">
              <messages-chart ref="chart" :days="'30'" />
            </div>
            <div class="card-footer bg-light d-flex justify-content-between">
              <router-link to="/sms/dashboard" class="btn btn-secondary btn-outline">SMS</router-link>
              <router-link
                to="/sms/history"
                class="btn btn-secondary btn-outline"
              >{{$t('generic-str.menu.history')}}</router-link>
            </div>
          </div>
          <div v-if="$store.state.stage <= 0" class="card">
            <div class="card-header">Whitelabel</div>
            <div class="card-body">
              Crie um contact center omnichannel em questão de minutos e personalize todos os
              elementos da experiência.
            </div>
            <div class="card-footer bg-light">
              <button type="button" class="btn btn-secondary btn-outline">Whitelabel</button>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-6">
          <div class="row">
            <div class="col-6">
              <router-link
                to="/users"
                tag="div"
                role="button"
                class="card bg-account text-center p-3"
              >
                <h5 class="text-white info-home">{{$t('home.basic-info.lbl-users')}}</h5><!-- Usuários -->
                <span class="text-white font-size-22 font-w-300">{{ users }}</span>
              </router-link>
            </div>
            <div class="col-6">
              <router-link
                to="/billing"
                tag="div"
                role="button"
                class="card bg-primary text-center p-3"
              >
                <h5 class="text-white info-home">{{$t('home.basic-info.lbl-balance')}}</h5><!-- Saldo da Conta -->
                <span
                  class="text-white font-size-22 font-w-300"
                >{{ account.balance | currency }}</span>
              </router-link>
            </div>
          </div>
          <invite-card />
          <div v-if="$store.state.stage <= 0" class="card">
            <div class="card-header">{{$t('generic-str.voice')}}</div>
            <div class="card-body">
              <ul class="list-group">
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >{{$t('home.conf-call')}}</li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  {{$t('home.msg')}} x
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                >{{$t('home.control')}}</li>
              </ul>
            </div>
            <div class="card-footer bg-light">
              <button type="button" class="btn btn-secondary btn-outline">{{$t('generic-str.voice')}}</button>
            </div>
          </div>
          <div class="card">
            <div class="card-header">API</div>
            <div
              class="card-body"
            >{{$t('home.api-div.string')}}</div><!-- Desenvolva uma experiência personalizada utilizando a API YupChat. -->
            <div class="card-footer bg-light">
              <a
                href="https://doc.yup.chat/"
                target="_blank"
                class="btn btn-secondary btn-outline"
              >API</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UserService from '@/services/user.service';
import PageHeader from '@/components/PageHeader.vue';
import InviteCard from '@/components/InviteCard.vue';
import MessagesChart from '@/components/sms/MessagesChart.vue';
import HiddenInput from '@/components/HiddenInput.vue';

export default {
  name: 'Home',
  components: {
    PageHeader,
    InviteCard,
    MessagesChart,
    HiddenInput,
  },
  data() {
    return {
      sms: true,
      users: 0,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.countUsers();

    this.$root.$on('change.account', () => {
      if (this.$refs.chart) {
        this.countUsers();
        this.$refs.chart.fetch();
      }
    });
  },
  methods: {
    countUsers() {
      UserService.countUsers().then(
        (response) => {
          this.users = response.total;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
tbody tr {
  cursor: pointer;
  &:hover {
    background-color: #fafafa; /* #f0f6ff */
  }
}

.bg-account {
  background-color: #752de6;
}
</style>
